import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import One from './Pages/One';
import Two from './Pages/Two';
import { ThemeProvider } from './Context/ThemeContext';
import { ChartProvider } from './Context/ChartContext';
import { LocationProvider } from './utils/LocationContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

const Contexts = ({ children }) => (
    <ThemeProvider>
        <LocationProvider>
            <ChartProvider>
                {children}
            </ChartProvider>
        </LocationProvider>
    </ThemeProvider>
);

const Rounting = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<One />} />

            <Route
                path="two"
                element={(
                    <Two />
                )}
            />

            <Route path="*" element={<One />} />
        </Routes>

    </BrowserRouter>
);

root.render(
    <React.StrictMode>
        <Contexts>
            <Rounting />
        </Contexts>
    </React.StrictMode>,
);
