import clsx from 'clsx';
import React from 'react';
import { LOGO_SRC, LOGO_ALT } from '../utils/config';
import useMedia from '../utils/MediaQuery';

export default function Loader() {
    const isDesktop = useMedia('(min-width: 900px)');
    return (
        <div className="w-screen absolute flex inset-0 flex-col items-center justify-center">
            { !isDesktop && (
                <img className="w-48" src={LOGO_SRC} alt={LOGO_ALT} />
            )}
            <svg
                className={clsx('animate-spin -ml-1 mr-3 h-10 w-10 mt-6', isDesktop ? 'text-bleu-benin' : 'text-white')}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
        </div>
    );
}
