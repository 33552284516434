import React, {
    createContext, useEffect, useMemo, useState,
} from 'react';
import useLocalStorage from '../utils/localStorage';

export const ChartContext = createContext();

export const ChartProvider = ({ children }) => {
    const [chartStorage, setChartStorage] = useLocalStorage(
        'chartChoose',
        'true',
    );
    const [chart, setChart] = useState(chartStorage);

    useEffect(() => {
        setChartStorage(chart);
    }, [chart]);

    const providedValues = useMemo(() => ({ chart, setChart }));

    return (
        <ChartContext.Provider value={providedValues}>
            {children}
        </ChartContext.Provider>
    );
};
