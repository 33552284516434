import React, {
    createContext, useEffect, useMemo, useState,
} from 'react';
import useLocalStorage from '../utils/localStorage';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [themeStorage, setThemeStorage] = useLocalStorage(
        'colorTheme',
        window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
    );
    const [theme, setTheme] = useState(themeStorage);

    useEffect(() => {
        window.document.documentElement.classList.remove('light', 'dark');
        window.document.documentElement.classList.add(theme);
        setThemeStorage(theme);
    }, [theme]);

    const providedValues = useMemo(() => ({ theme, setTheme }));

    return (
        <ThemeContext.Provider value={providedValues}>
            {children}
        </ThemeContext.Provider>
    );
};
