/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import Header from '../partials/Header';
import QoSDonutChart from '../components/QoSDonutChart';
import { getThresholdColor } from '../utils/Threshold';
import Operators from '../partials/Operators';
import { LocationContext } from '../utils/LocationContext';
import { OPERATORS } from '../utils/config';
import { fetchOk } from '../utils/Api';

const QosCardWrapper = ({
    data, title, operator, network, service, heightValue,
}) => (
    <QoSDonutChart
        key={`data-${operator}-${network}-${service}`}
        title={title}
        subtitle=""
        range={service === 'internet' ? (network === '3g' ? 1000 : 50) : 100}
        value={(data[operator][`${network}_${service}`] ?? null)}
        color={getThresholdColor(service === 'voice' ? 'sa' : 'si', network, (data[operator][`${network}_${service}`] ?? null))}
        unit={service === 'internet' ? (network === '3g' ? 'Kbps' : 'Mbps') : '%'}
        style={{
            height: heightValue, width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}
    />
);

function One() {
    // const data = Array(18).from(() => random(0, 100, true));
    const [dataQos, setDataQos] = useState(null);

    const [locationList, locationsUpdating, selectedLocation, setSelectedLocation] = useContext(LocationContext);

    useEffect(() => {
        if (selectedLocation !== null) {
            const defaultData = Object.fromEntries(
                OPERATORS.map((o) => [o.name, {}]),
            );
            fetchOk(`https://atlas.arcep.bj/api/qos-data/${selectedLocation.type}/${selectedLocation.id}`).then((r) => r.json())
                .then(({ data }) => {
                    setDataQos({ ...defaultData, ...data });
                    // setOperator((prevOperator) => (prevOperator.length > 0 ? prevOperator : [Object.keys(data).sort()[0]]));
                }).catch((e) => console.error(e));
        }
    }, [selectedLocation]);

    console.log(dataQos);

    return (
        <div className="w-full h-screen z-0 flex flex-col items-stretch relative dark:bg-gray-800">

            {/* Header */}
            <div className="grid grid-cols-1 grid-rows-1 gap-2 py-2 px-2">
                <Header />
            </div>

            {/* Operators lists */}
            <Operators />

            {/* Graphic */}
            <div className="grid grid-cols-3 grid-rows-3 gap-2 py-2 px-2 flex-grow relative z-0">
                <div
                    className="flex justify-center p-6 text-6xl bg-white dark:bg-gray-900 drop-shadow-lg rounded-lg row-span-2 flex-wrap"
                >
                    {/* MTN */}
                    {
                        dataQos !== null && (
                            <>
                                <QosCardWrapper
                                    title="Ericsson 2G VOICE"
                                    data={dataQos}
                                    operator="MTN"
                                    service="voice"
                                    network="2g"
                                    heightValue="50%"
                                />
                                <QosCardWrapper
                                    title="Huawei 2G VOICE"
                                    data={dataQos}
                                    operator="MTN"
                                    service="voice"
                                    network="2g"
                                    heightValue="50%"
                                />
                                <QosCardWrapper
                                    title="Ericsson 3G VOICE"
                                    data={dataQos}
                                    operator="MTN"
                                    service="voice"
                                    network="3g"
                                    heightValue="50%"
                                />
                                <QosCardWrapper
                                    title="Huawei 3G VOICE"
                                    data={dataQos}
                                    operator="MTN"
                                    service="voice"
                                    network="3g"
                                    heightValue="50%"
                                />
                            </>
                        )
                    }
                </div>
                <div
                    className="flex justify-center p-6 text-6xl bg-white dark:bg-gray-900 drop-shadow-lg rounded-lg row-span-2 flex-wrap"
                >
                    {/* MOOV */}
                    {
                        dataQos !== null && (
                            <>
                                <QosCardWrapper
                                    title="Ericsson 2G VOICE"
                                    data={dataQos}
                                    operator="MOOV"
                                    service="voice"
                                    network="2g"
                                    heightValue="50%"
                                />
                                <QosCardWrapper
                                    title="Huawei 2G VOICE"
                                    data={dataQos}
                                    operator="MOOV"
                                    service="voice"
                                    network="2g"
                                    heightValue="50%"
                                />
                                <QosCardWrapper
                                    title="Ericsson 3G VOICE"
                                    data={dataQos}
                                    operator="MOOV"
                                    service="voice"
                                    network="3g"
                                    heightValue="50%"
                                />
                                <QosCardWrapper
                                    title="Huawei 3G VOICE"
                                    data={dataQos}
                                    operator="MOOV"
                                    service="voice"
                                    network="3g"
                                    heightValue="50%"
                                />
                            </>
                        )
                    }
                </div>
                <div
                    className="flex justify-center items-center text-center p-6 text-6xl bg-white dark:bg-gray-900 drop-shadow-lg rounded-lg row-span-2 flex-wrap"
                >
                    <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                        <div className="sm:flex sm:items-start">
                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z" />
                                </svg>
                            </div>
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">Opérateur non disponible</h3>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">L'opérateur CELTIIS n'est pas disponible, car il n'est pas encore disponible sur RPM System.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {
                        chart === 'true' ? (
                            <>

                                <QoSDonutChart
                                    key={`data-${data[0]}`}
                                    title="Ericsson 2G"
                                    subtitle="kjzekzf"
                                    value={data[0]}
                                    color={getThresholdColor('sa', '2g', data[0])}
                                    unit="%"
                                    style={{
                                        height: '50%', width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center',
                                    }}
                                />
                                <QoSDonutChart
                                    key={`data-${data[1]}`}
                                    title="Ericsson 3G"
                                    subtitle="kjzekzf"
                                    value={data[1]}
                                    color={getThresholdColor('sa', '3g', data[1])}
                                    unit="%"
                                    style={{
                                        height: '50%', width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center',
                                    }}
                                />
                                <QoSDonutChart
                                    key={`data-${data[2]}`}
                                    title="Huawei 2G"
                                    subtitle="kjzekzf"
                                    value={data[2]}
                                    color={getThresholdColor('sa', '2g', data[2])}
                                    unit="%"
                                    style={{
                                        height: '50%', width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center',
                                    }}
                                />
                                <QoSDonutChart
                                    key={`data-${data[3]}`}
                                    title="Huawei 3G"
                                    subtitle="kjzekzf"
                                    value={data[3]}
                                    color={getThresholdColor('sa', '3g', data[3])}
                                    unit="%"
                                    style={{
                                        height: '50%', width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center',
                                    }}
                                />
                            </>
                        ) : (
                            <QoSChart />
                        )
                    } */}
                </div>

                <div
                    className="flex justify-center p-6 text-6xl bg-white dark:bg-gray-900 drop-shadow-lg rounded-lg"
                >
                    {/* MTN */}
                    {
                        dataQos !== null && (
                            <>
                                <QosCardWrapper
                                    title="Mixed KPI 2G VOICE"
                                    data={dataQos}
                                    operator="MTN"
                                    service="voice"
                                    network="2g"
                                    heightValue="100%"
                                />
                                <QosCardWrapper
                                    title="Mixed KPI 3G VOICE"
                                    data={dataQos}
                                    operator="MTN"
                                    service="voice"
                                    network="3g"
                                    heightValue="100%"
                                />
                            </>
                        )
                    }
                </div>

                <div
                    className="flex justify-center p-6 text-6xl bg-white dark:bg-gray-900 drop-shadow-lg rounded-lg"
                >
                    {/* MOOV */}
                    {
                        dataQos !== null && (
                            <>
                                <QosCardWrapper
                                    title="Mixed KPI 2G VOICE"
                                    data={dataQos}
                                    operator="MOOV"
                                    service="voice"
                                    network="2g"
                                    heightValue="100%"
                                />
                                <QosCardWrapper
                                    title="Mixed KPI 3G VOICE"
                                    data={dataQos}
                                    operator="MOOV"
                                    service="voice"
                                    network="3g"
                                    heightValue="100%"
                                />
                            </>
                        )
                    }
                </div>

                <div
                    className="flex justify-center items-center text-center p-6 text-6xl bg-white dark:bg-gray-900 drop-shadow-lg rounded-lg"
                >
                    <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                        <div className="sm:flex sm:items-start">
                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z" />
                                </svg>
                            </div>
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">Opérateur non disponible</h3>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">L'opérateur CELTIIS n'est pas disponible, car il n'est pas encore disponible sur RPM System.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {
                        chart === 'true' ? (
                            <>
                                <QoSDonutChart
                                    key={`data-${data[16]}`}
                                    title="Mix KPI 2G"
                                    subtitle="kjzekzf"
                                    value={data[16]}
                                    color={getThresholdColor('sa', '2g', data[16])}
                                    unit="%"
                                    style={{
                                        height: '100%', width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center',
                                    }}
                                />
                                <QoSDonutChart
                                    key={`data-${data[17]}`}
                                    title="Mix KPI 3G"
                                    subtitle="kjzekzf"
                                    value={data[17]}
                                    color={getThresholdColor('sa', '2g', data[17])}
                                    unit="%"
                                    style={{
                                        height: '100%', width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center',
                                    }}
                                />

                            </>
                        ) : (
                            <QoSChartMixKPI title="Mix KPI" />
                        )
                    } */}
                </div>
            </div>

        </div>
    );
}

export default One;
