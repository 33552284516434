import React, { useState } from 'react';
import { HiArrowsExpand, HiXCircle } from 'react-icons/hi';

const ToggleFullscreen = () => {
    const [fullscreen, setFullscreen] = useState(false);
    const Icon = fullscreen ? HiXCircle : HiArrowsExpand;

    return (
        <div className="transition duration-500 ease-in-out rounded-full p-2">
            <Icon
                onClick={() => {
                    if (!document.fullscreenElement) {
                        document.querySelector('#fullscreen')?.requestFullscreen();
                        setFullscreen(true);
                    } else {
                        document.exitFullscreen();
                        setFullscreen(false);
                    }
                }}
                className="text-gray-500 dark:text-gray-400 text-2xl cursor-pointer"
            />
        </div>
    );
};

export default ToggleFullscreen;
