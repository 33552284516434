import React from 'react';

export default function Operators() {
    return (
        <div className="grid grid-cols-3 grid-rows-1 gap-2 px-2 relative z-0">

            <div
                className="flex justify-center items-center p-4 text-xl font-bold bg-white dark:bg-gray-900 drop-shadow-md rounded-lg row-span-2 flex-wrap"
            >
                <div className="flex justify-start align-items-stretch">
                    <div className="mr-4 text-black dark:text-white rounded-md">
                        <div className="justify-self-end">
                            <img src="../images/operators/MTN.png" alt="MTN" className="w-16 absolute rounded-md sm:relative sm:top-0 sm:right-0" />
                        </div>
                    </div>
                </div>
                MTN
            </div>

            <div
                className="flex justify-center items-center p-4 text-xl font-bold bg-white dark:bg-gray-900 drop-shadow-lg rounded-lg row-span-2 flex-wrap"
            >
                <div className="flex justify-start align-items-stretch">
                    <div className="mr-4 text-black dark:text-white rounded-md">
                        <div className="justify-self-end">
                            <img src="../images/operators/MOOV.png" alt="MOOV" className="w-16 absolute rounded-md sm:relative sm:top-0 sm:right-0" />
                        </div>
                    </div>
                </div>
                MOOV
            </div>

            <div
                className="flex justify-center items-center p-4 text-xl font-bold bg-white dark:bg-gray-900 drop-shadow-lg rounded-lg row-span-2 flex-wrap"
            >
                <div className="flex justify-start align-items-stretch">
                    <div className="mr-4 text-black dark:text-white rounded-md">
                        <div className="justify-self-end">
                            <img src="../images/operators/CELTIIS.png" alt="CELTIIS" className="w-16 absolute rounded-md sm:relative sm:top-0 sm:right-0" />
                        </div>
                    </div>
                </div>
                CELTIIS
            </div>

        </div>
    );
}
