/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Loader from '../components/Loader';
import ToggleFullscreen from '../components/ToggleFullscreen';
import ToggleTheme from '../components/ToggleTheme';
import { LocationContext } from '../utils/LocationContext';
// import { NavLink } from 'react-router-dom';
// import ToggleFullscreen from '../components/ToggleFullscreen';
// import ToggleTheme from '../components/ToggleTheme';
// import ToogleComponents from '../components/ToogleComponents';
import SearchModal from './header/SearchModal';

export default function Header() {
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const pathLocation = useLocation();

    const [locationList, locationsUpdating, selectedLocation, setSelectedLocation] = useContext(LocationContext);

    if (locationList === null || !!locationsUpdating) {
        return (
            <Loader />
        );
    }

    return (
        <header className="sticky top-0 bg-white border-b border-slate-200 z-30">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16 -mb-px">

                    {/* Header: Left side */}
                    <div className="flex">

                        {/* Hamburger button */}
                        <div className="flex items-center">
                            <NavLink
                                to="/"
                                className={({ isActive }) => (isActive ? 'rounded-xl h-10 px-3 flex justify-center items-center m-2 bg-green-500 text-white' : 'rounded-xl h-10 px-3 flex justify-center items-center m-2 bg-gray-400')}
                            >
                                VOICE
                            </NavLink>
                            <NavLink
                                to="/two"
                                className={({ isActive }) => (isActive ? 'rounded-xl h-10 px-3 flex justify-center items-center m-2 bg-green-500 text-white' : 'rounded-xl h-10 px-3 flex justify-center items-center m-2 bg-gray-400')}
                            >
                                DATA
                            </NavLink>
                        </div>

                    </div>

                    <div>
                        <h1 className="text-black dark:text-white text-center text-xl uppercase font-bold">
                            Ecran de supervision
                            {' '}
                            {pathLocation.pathname === '/two' ? 'DATA' : 'VOICE'}
                            {' '}
                            {selectedLocation ? `- ${selectedLocation.name}` : '' }
                        </h1>
                    </div>

                    {/* Header: Right side */}
                    <div className="flex items-center">
                        <div className="px-2">
                            <button
                                type="button"
                                className={`w-8 h-8 flex items-center justify-center transition duration-150 rounded-full ml-3 ${searchModalOpen && 'bg-slate-200'}`}
                                onClick={(e) => { e.stopPropagation(); setSearchModalOpen(true); }}
                                aria-controls="search-modal"
                            >
                                <span className="sr-only">Search</span>
                                <svg className="w-4 h-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <path className="fill-current text-slate-500" d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                    <path className="fill-current text-slate-400" d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                                </svg>
                            </button>
                        </div>
                        <SearchModal
                            id="search-modal"
                            searchId="search"
                            modalOpen={searchModalOpen}
                            setModalOpen={setSearchModalOpen}
                            locationList={locationList}
                            onChange={(location) => { setSelectedLocation(location); }}
                        />
                        <div className="px-2">
                            <ToggleFullscreen />
                        </div>
                        {/*  Divider */}
                        <div className="px-2">
                            <ToggleTheme />
                        </div>

                    </div>

                </div>
            </div>
        </header>
    );
}
