/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useEffect, useState } from 'react';
import { fetchOk } from './Api';
import useLocalStorage from './localStorage';

const LocationContext = createContext();

function LocationProvider({ children }) {
    const [apiLocationCount, setApiLocationCount] = useState(null);
    const [locationsUpdating, setLocationsUpdating] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [locationList, setLocationList] = useLocalStorage('locationList', null);
    const [cacheVersion, setCacheVersion] = useLocalStorage('cacheVersion', null);

    const fetchLocations = () => {
        setLocationsUpdating(true);
        Promise.all([
            'https://atlas.arcep.bj/api/countries',
            'https://atlas.arcep.bj/api/departments',
            'https://atlas.arcep.bj/api/cities',
            // 'https://atlas.arcep.bj/api/districts',
        ].map((url) => fetchOk(url).then((r) => r.json())))
            .then(([countries, departments, cities]) => {
                setLocationList([
                    ...countries.data,
                    ...departments.data,
                    ...cities.data,
                    // ...districts.data,
                ]);
                // eslint-disable-next-line no-undef
                setLocationsUpdating(false);
                setSelectedLocation(countries.data[0]);
            }).catch((e) => console.error(e));
    };

    useEffect(() => {
        // eslint-disable-next-line no-undef
        if (locationList === null || cacheVersion === null || cacheVersion !== CACHE_VERSION) {
            fetchLocations();
        } else if (apiLocationCount === null) {
            setLocationsUpdating(true);
            fetchOk('https://atlas.arcep.bj/api/location-count')
                .then((r) => r.json())
                .then((res) => {
                    const apiCount = parseInt(res.data, 10);
                    setApiLocationCount(apiCount);
                    if (apiCount !== locationList.length) {
                        fetchLocations();
                    } else {
                        setLocationsUpdating(false);
                    }
                }).catch((e) => console.error(e));
        }
    }, []);

    return (
        <LocationContext.Provider value={[locationList, locationsUpdating, selectedLocation, setSelectedLocation]}>
            {children}
        </LocationContext.Provider>
    );
}

export { LocationContext, LocationProvider };
