const colors = {
    green:  '#3CB371',
    orange: '#EE8539',
    yellow: '#facc15',
    red:    '#E31A1C',
    black:  '#000000',
};

export const getThresholdLegend = (kpi, network) => {
    if (kpi === 'si') {
        if (network === '4g') {
            return {
                display:      (v) => (Math.round(v * 100) / 100),
                legendUnit:   'Mbps',
                legendValues: [
                    { color: colors.red, from: 0, to: 1 },
                    { color: colors.orange, from: 1, to: 3 },
                    { color: colors.yellow, from: 3, to: 5 },
                    { color: colors.green, from: 5, to: 20 },
                ],
            };
        }
        return {
            display:      (v) => (Math.round(v * 100) / 100),
            legendUnit:   'Kbps',
            legendValues: [
                { color: colors.red, from: 0, to: 1000 },
                { color: colors.orange, from: 1000, to: 1500 },
                { color: colors.yellow, from: 1500, to: 2000 },
                { color: colors.green, from: 2000, to: 5000 },
            ],
        };
    }
    if (kpi === 'sr') {
        return {
            display:      (v) => (Math.round(v * 10000) / 100),
            legendUnit:   '%',
            legendValues: [
                { color: colors.green, from: 0, to: 3 },
                { color: colors.yellow, from: 3, to: 5 },
                { color: colors.orange, from: 5, to: 10 },
                { color: colors.red, from: 10, to: 20 },
            ],
        };
    }
    return {
        display:      (v) => (Math.round(v * 100) / 100),
        legendUnit:   '%',
        legendValues: [
            { color: colors.red, from: 0, to: 50 },
            { color: colors.orange, from: 50, to: 80 },
            { color: colors.yellow, from: 80, to: 90 },
            { color: colors.green, from: 90, to: 100 },
        ],
    };
};

export const getThresholdUnit = (kpi, network) => {
    if (kpi === 'si') {
        if (network === '4g') {
            return [100, 30, 25, 20];
        }
        return [1000, 800, 700, 600];
    }
    if (kpi === 'sr') {
        return [0, 0.5, 1, 2];
    }
    return [100, 99.9, 99, 95];
};

export const getThreshold = (kpi, network, value) => {
    if (kpi === 'si') {
        if (network === '4g') {
            if (value >= 5) {
                return 'good';
            } if (value >= 3) {
                return 'fair';
            } if (value >= 1) {
                return 'poor';
            } if (value < 1) {
                return 'very poor';
            }
        }
        if (value > 2000) {
            return 'good';
        } if (value >= 1500) {
            return 'fair';
        } if (value >= 1000) {
            return 'poor';
        } if (value < 1000) {
            return 'very poor';
        }
    }
    if (kpi === 'sr') {
        if (value < 3) {
            return 'good';
        } if (value <= 5) {
            return 'fair';
        } if (value <= 10) {
            return 'poor';
        } if (value > 10) {
            return 'very poor';
        }
    }
    if (value > 90) {
        return 'good';
    } if (value >= 80) {
        return 'fair';
    } if (value >= 50) {
        return 'poor';
    } if (value < 50) {
        return 'very poor';
    }

    return 'no data';
};

export const getColorFromThreshold = (threshold) => {
    switch (threshold.toLowerCase()) {
    case 'good':
        return colors.green;
    case 'fair':
        return colors.yellow;
    case 'poor':
        return colors.orange;
    case 'very poor':
        return colors.red;
    case 'no data':
    default:
        return colors.black;
    }
};

export const getThresholdColor = (kpi, network, value) => {
    const threshold = getThreshold(kpi, network, value);
    return getColorFromThreshold(threshold);
};
