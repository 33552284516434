import React, { useContext } from 'react';
import { HiMoon, HiSun } from 'react-icons/hi';
import { ThemeContext } from '../Context/ThemeContext';

const ToggleTheme = () => {
    const { theme, setTheme } = useContext(ThemeContext);
    const Icon = theme === 'dark' ? HiSun : HiMoon;

    return (
        <div className="transition duration-500 ease-in-out rounded-full p-2">
            <Icon
                onClick={() => setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'))}
                className="text-gray-500 dark:text-gray-400 text-2xl cursor-pointer"
            />
        </div>
    );
};

export default ToggleTheme;
