export const LOGO_SRC = './images/logo/Arcep-large.png';

export const SITE_NAME = 'Atlas de couverture - Bénin';

export const SITE_URL = 'https://atlas.arcep.bj';

export const LOGO_ALT = 'Logo de l\'Autorité de Régulation des Communications Electroniques et de la Postes du Bénin';

export const OPERATORS = [{
    id:   1,
    name: 'MOOV',
    src:  './images/operators/MOOV.jpeg',
}, {
    id:   2,
    name: 'MTN',
    src:  './images/operators/MTN.jpeg',
}];

const getTypeLabel = (type) => {
    switch (type) {
    case 'department':
        return 'Département';
    case 'city':
        return 'Commune';
    case 'district':
        return 'Arrondissement';
    case 'country':
        return 'Pays';
    default:
        return '';
    }
};

export default getTypeLabel;
