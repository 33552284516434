import React from 'react';

export default function SearchResultItem({ data, onClick, getTypeLabel }) {
    return (

        <li>
            <button
                className="flex w-full items-center p-2 text-slate-800 hover:text-white hover:bg-indigo-500 rounded group"
                type="button"
                onClick={() => {
                    onClick(data);
                }}
            >
                <img className="h-12 w-12" src={`../images/icons/${data.type}-40px.svg`} alt="" />
                <span>
                    {data.name}
                    {' '}
                    -
                    {' '}
                    {getTypeLabel(data.type)}
                </span>
            </button>
        </li>
    );
}
