import { round } from 'lodash';
import React from 'react';
import CustomEchart from './CustomEchart';

export default function QoSDonutChart(props) {
    const {
        value, color, unit, title, range, subtitle, style = { height: '18Opx', width: '100%' },
    } = props;
    let displayValue = value;
    if (displayValue !== null) {
        displayValue = round(displayValue, 2);
    }
    const option = {
        title: [
            {
                text:      title,
                show:      true,
                top:       2,
                left:      'center',
                textStyle: {
                    fontWeight: 'bolder',
                    fontSize:   20,
                },
            },
            {
                text:      subtitle,
                show:      false,
                top:       30,
                left:      'center',
                textStyle: {
                    fontWeight: 'bold',
                    fontSize:   15,
                },
            },
        ],
        // toolbox: {
        //     feature: {
        //         saveAsImage: {
        //             title: 'Export to png',
        //             name:  `${title} - ${subtitle}`,
        //         },
        //     },
        // },
        grid: {
            left:         0,
            right:        0,
            top:          0,
            bottom:       0,
            containLabel: false,
        },
        series: [
            {
                type:     'gauge',
                radius:   '75%',
                center:   ['50%', '60%'],
                min:      0,
                // Max value à changer selon l'unité (% = 100, Kbps = ??, Mbps = ??)
                // A voir avec Tahitii
                max:      range,
                progress: {
                    show:      true,
                    width:     15,
                    itemStyle: {
                        // Couleur à déterminer dynamiquement avec les fonctions dans ChoroplethGenerator
                        color,
                    },
                },
                axisLine: {
                    lineStyle: {
                        width: 15,
                    },
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    length:    18,
                    lineStyle: {
                        width: 2,
                        color: '#999',
                    },
                    show: false,
                },
                axisLabel: {
                    distance: 25,
                    color:    '#999',
                    fontSize: 20,
                    show:     false,
                },
                anchor: {
                    show:      false,
                    showAbove: false,
                    size:      25,
                    itemStyle: {
                        borderWidth: 10,
                    },
                },
                pointer: {
                    show: false,
                },
                title: {
                    show: false,
                },
                detail: {
                    valueAnimation: true,
                    offsetCenter:   [0, 0],
                    fontSize:       20,
                    formatter:      () => (`${displayValue}${unit === '%' ? ' ' : '\n'}${unit}`),
                    // Besoin de 3 \n pour vraiment aller à la ligne
                },
                data: [
                    {
                        value,
                    },
                ],
            },
        ],
    };

    return (
        <CustomEchart option={option} style={style} />
    );
}
